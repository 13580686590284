import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import {CheckAuth} from "./checkWebApp";
import CafeControl from "./components/Cafe";
import CategoryControl from "./components/Category";
import ProductControl from "./components/Product";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<CheckAuth/>}/>
                <Route path='/cafe' element={<CafeControl/>}/>
                <Route path='/category/:cafeId' element={<CategoryControl/>}/>
                <Route path='/product/:params' element={<ProductControl/>}/>
            </Routes>
        </Router>
    );
};

export default App;
