import React, {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    Input,
    Link as ChakraLink,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Text
} from '@chakra-ui/react';
import {Link as ReactRouterLink, useNavigate, useParams} from 'react-router-dom';
import {Category, themeParams} from "../api";
import {FaPen, FaTrash} from 'react-icons/fa';
import {changeBackgroundColor} from '../theme';

export default function CategoryControl() {
    const {cafeId} = useParams(); // Get cafeId from URL parameters
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    changeBackgroundColor(themeParams.bg_color);
    Telegram.WebApp.BackButton.onClick(() => navigate('/cafe'));
    Telegram.WebApp.BackButton.show()
    useEffect(() => {
        async function fetchCategories() {
            try {
                const response = await Category.get(cafeId);
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchCategories();
    }, [cafeId]);

    const deleteCategory = async (id) => {
        try {
            await Category.delete(id);
            setCategories((prevCategories) => prevCategories.filter(category => category.id !== id));
        } catch (error) {
            if (error.detail && error.detail === 'CategoryNotFound') {
                Telegram.WebApp.showAlert('Bu kategoriya mavjud emas');
            } else {
                Telegram.WebApp.showAlert('Kutilmagan xatolik');
            }
        }
    }

    const handleDelete = (id) => {
        Telegram.WebApp.showConfirm('Ushbu kategoriyani o\'chirilishini tasdqilang?', function (isConfirmed) {
            if (isConfirmed) {
                deleteCategory(id);
            }
        });
    };

    const handleCreateNewCategory = () => {
        setSelectedCategoryId(null);
        setCategoryName('');
        setError('');
        setIsModalOpen(true);
    };

    const handleEdit = (id, name) => {
        setSelectedCategoryId(id);
        setCategoryName(name);
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setCategoryName('');
        setSelectedCategoryId(null);
        setError('');
    };
    Telegram.WebApp.MainButton.onClick(handleCreateNewCategory);
    Telegram.WebApp.MainButton.text = 'Yangi kategoriya yaratish'
    Telegram.WebApp.MainButton.show();

    const handleSubmit = async () => {
        const validationError = validateName(categoryName);
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            if (selectedCategoryId) {
                await Category.update(selectedCategoryId, categoryName);
                setCategories(categories.map(category => category.id === selectedCategoryId ? {
                    ...category,
                    name: categoryName
                } : category));
            } else {
                const createdCategory = await Category.create(cafeId, categoryName);
                setCategories((prevCategories) => [...prevCategories, createdCategory.data]);
            }
            setIsModalOpen(false);
            setCategoryName('');
            setSelectedCategoryId(null);
        } catch (error) {
            if (error.detail && error.detail === 'UserLimitExceeded') {
                setError('Sizning kategoriya yaratish limitingiz tugadi');
            } else if (error.detail && error.detail === 'CafeNotFound') {
                setError('Bu kafe mavjud emas');
            } else {
                setError('Kutilmagan xatolik');
            }
        }
    };

    const validateName = (value) => {
        let error = '';
        if (!value) {
            error = 'Kategoriya nomini kiriting';
        } else if (value.length > 20) {
            error = "Kategoriya nomi uzunligi eng ko'pi 20ta bo'lishi kerak";
        }
        return error;
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setCategoryName(value);
        setError(validateName(value));
    };

    if (loading) {
        return (
            <Stack spacing={4} direction='column' align='center' marginTop='100px'>
                <Spinner size="xl" color={themeParams.link_color}/>
                <Text color={themeParams.text_color}>Yuklanmoqda...</Text>
            </Stack>
        );
    }

    return (
        <>
            <Stack spacing={4} direction='column' align='center' marginTop='10px'>
                <Button backgroundColor={themeParams.link_color} size='sm' width='370px'>
                    <Text fontSize='xl' color={themeParams.button_text_color}>Kategoriyalar</Text>
                </Button>
                {categories.map((category) => (
                    <Stack key={category.id} direction='row' spacing={2} alignItems='center'>
                        <ChakraLink as={ReactRouterLink} to={`/product/${category.id}-${cafeId}`}>
                            <Button
                                backgroundColor={themeParams.link_color}
                                size='lg'
                                width='250px'
                            >
                                <Text color={themeParams.button_text_color}>{category.name}</Text>
                            </Button>
                        </ChakraLink>
                        <IconButton
                            icon={<FaPen/>}
                            backgroundColor='blue'
                            color='white'
                            size='lg'
                            aria-label={`Edit ${category.name}`}
                            width='50px'
                            onClick={() => handleEdit(category.id, category.name)}
                        />
                        <IconButton
                            icon={<FaTrash/>}
                            backgroundColor='red'
                            color='white'
                            size='lg'
                            aria-label={`Delete ${category.name}`}
                            width='50px'
                            onClick={() => handleDelete(category.id)}
                        />
                    </Stack>
                ))}
            </Stack>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay/>
                <ModalContent backgroundColor={themeParams.section_bg_color}>
                    <ModalHeader color={themeParams.text_color}>
                        {selectedCategoryId ? "Kategoriya nomini o'zgartirish" : "Yangi kategoriya yaratish"}
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            placeholder='Kategoriya nomini kiriting'
                            value={categoryName}
                            onChange={handleNameChange}
                            isInvalid={!!error}
                            errorBorderColor='red.300'
                            width='100%'
                            color={themeParams.text_color}
                        />
                        {error && <Text color='red.500'>{error}</Text>}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={handleCancel}>
                            <Text variant='ghost' color={themeParams.text_color}>Bekor qilish</Text>
                        </Button>
                        <Button backgroundColor={themeParams.link_color} onClick={handleSubmit}>
                            <Text
                                color={themeParams.button_text_color}>{selectedCategoryId ? "Saqlash" : "Yaratish"}</Text>
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
