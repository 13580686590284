import axios from 'axios';
// import Telegram from './tgwebapp'
const API_URL = 'https://foodmenu.uz'
export const themeParams = Telegram.WebApp.themeParams;

export function checkAuth() {
    Telegram.WebApp.ready()
    let initData = Telegram.WebApp.initData || '';
    console.log(initData)
    if (initData) {
        fetch(API_URL + '/user/check-webapp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({auth: initData})
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    Telegram.Utils.sessionStorageSet('token', data.access_token)
                    window.location.href = '/cafe'
                } else {
                    console.log(data)
                    // window.location.href = 'https://foodmenu.uz'
                    return false;
                }
            })
            .catch(error => {
                console.error('Error:', error);
                // window.location.href = 'https://foodmenu.uz'
                return false;
            });
    } else {
        console.error('Error fetching cafe');
        // window.location.href = 'https://foodmenu.uz'
    }
}

export function authenticatedRequest(path, method, body = {}) {
    const access_token = Telegram.Utils.sessionStorageGet('token')

    if (!access_token) {
        return Promise.reject('No access token available');
    }

    return axios({
        url: `${API_URL}${path}`,
        method: method,
        data: method === 'GET' ? undefined : body, // Include body only for non-GET requests
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${access_token}`,
        },
    })
        .then(response => response.data)
        .catch((error) => {
            if (error.response) {
                // Server responded with a status other than 2xx
                return Promise.reject(error.response.data);
            } else if (error.request) {
                // Request was made but no response received
                return Promise.reject('No response from server. Please try again.');
            } else {
                // Something else happened during request setup
                return Promise.reject(error.message);
            }
        });
}


export const Cafe = {
    create: function (name) {
        return authenticatedRequest('/cafe', 'POST', {name: name});
    },
    get: function () {
        return authenticatedRequest('/cafe', 'GET');
    },
    delete: function (id) {
        return authenticatedRequest('/cafe', 'DELETE', {id: id});
    },
    update: function (id, new_name) {
        return authenticatedRequest('/cafe', 'PATCH', {id: id, new_name: new_name});
    }
}

export const Category = {
    create: function (cafe_id, name) {
        return authenticatedRequest('/category', 'POST', {cafe_id: cafe_id, name: name});
    },
    get: function (cafe_id) {
        return authenticatedRequest('/category?cafe_id=' + cafe_id, 'GET');
    },
    delete: function (id) {
        return authenticatedRequest('/category', 'DELETE', {id: id});
    },
    update: function (id, new_name) {
        return authenticatedRequest('/category', 'PATCH', {id: id, new_name: new_name});
    }
}

export const Product = {
    create: function (category_id, name, price, discount = 0) {
        return authenticatedRequest('/product', 'POST', {
            category_id: category_id,
            name: name,
            price: price,
            discount: discount
        });
    },
    get: function (category_id) {
        return authenticatedRequest('/product?category_id=' + category_id, 'GET');
    },
    delete: function (id) {
        return authenticatedRequest('/product', 'DELETE', {id: id});
    },
    update: function (id, new_name, new_price, new_discount) {
        return authenticatedRequest('/product', 'PATCH', {
            id: id,
            new_name: new_name,
            new_price: new_price,
            new_discount: new_discount
        });
    }
}
