import React, {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    Input,
    Link as ChakraLink,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Text
} from '@chakra-ui/react';
import {Link as ReactRouterLink, useNavigate, useParams} from 'react-router-dom';
import {Product, themeParams} from "../api";
import {FaPen, FaTrash} from 'react-icons/fa';
import {changeBackgroundColor} from '../theme';

export default function ProductControl() {
    const {params} = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productDiscount, setProductDiscount] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const categoryId = params.split('-')[0]
    const cafeId = params.split('-')[1]

    useEffect(() => {
        // Configure Telegram WebApp MainButton
        Telegram.WebApp.MainButton.text = selectedProductId ? 'Saqlash' : 'Yangi mahsulot yaratish';
        Telegram.WebApp.MainButton.onClick(handleCreateNewProduct);
        Telegram.WebApp.MainButton.show();

        return () => {
            Telegram.WebApp.MainButton.hide();
        };
    }, [selectedProductId]);

    changeBackgroundColor(themeParams.bg_color);
    Telegram.WebApp.BackButton.onClick(() => navigate('/category/' + cafeId));
    Telegram.WebApp.BackButton.show();

    useEffect(() => {
        async function fetchProducts() {
            try {
                const response = await Product.get(categoryId);
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchProducts();
    }, [categoryId]);

    const deleteProduct = async (id) => {
        try {
            await Product.delete(id);
            setProducts((prevProducts) => prevProducts.filter(product => product.id !== id));
        } catch (error) {
            if (error.detail && error.detail === 'ProductNotFound') {
                Telegram.WebApp.showAlert('Bu mahsulot mavjud emas');
            } else {
                console.log(error)
                Telegram.WebApp.showAlert('Kutilmagan xatolik');
            }
        }
    };

    const handleDelete = (id) => {
        Telegram.WebApp.showConfirm('Ushbu mahsulotni o\'chirilishini tasdiqlang?', function (isConfirmed) {
            if (isConfirmed) {
                deleteProduct(id);
            }
        });
    };

    const handleCreateNewProduct = () => {
        setSelectedProductId(null);
        setProductName('');
        setProductPrice('');
        setProductDiscount('');
        setError('');
        setIsModalOpen(true);
    };

    const handleEdit = (id, name, price, discount) => {
        setSelectedProductId(id);
        setProductName(name);
        setProductPrice(price);
        setProductDiscount(discount);
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setProductName('');
        setProductPrice('');
        setProductDiscount('');
        setSelectedProductId(null);
        setError('');
    };

    const handleSubmit = async () => {
        const validationError = validateFields(productName, productPrice);
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            if (selectedProductId) {
                let discount = productDiscount;
                if (productDiscount === "") {
                    discount = 0
                    setProductDiscount('0')
                }
                if (discount > parseInt(productPrice)) {
                    return setError('Chegirma narxi asl narxdan katta bo\'lmaydi!')
                }
                await Product.update(selectedProductId, productName, productPrice, discount);
                setProducts(products.map(product => product.id === selectedProductId ? {
                    ...product,
                    name: productName,
                    price: productPrice,
                    discount: productDiscount
                } : product));
            } else {
                let discount = productDiscount;
                if (productDiscount === "") {
                    discount = 0
                    setProductDiscount('0')
                }
                if (discount > parseInt(productPrice)) {
                    return setError('Chegirma narxi asl narxdan katta bo\'lmaydi!')
                }
                const createdProduct = await Product.create(categoryId, productName, productPrice, discount);
                setProducts((prevProducts) => [...prevProducts, createdProduct.data]);
            }
            setIsModalOpen(false);
            setProductName('');
            setProductPrice('');
            setProductDiscount('');
            setSelectedProductId(null);
        } catch (error) {
            if (error.detail && error.detail === 'ProductNotFound') {
                setError('Bu mahsulot mavjud emas');
            } else if (error.detail && error.detail === 'UserLimitExceeded')
                setError('Mahsulot yaratish limitingiz tugadi')
            else {
                setError('Kutilmagan xatolik');
            }
        }
    };

    const validateFields = (name, price) => {
        let error = '';
        if (!name) {
            error = 'Mahsulot nomini kiriting';
        } else if (!price || isNaN(price)) {
            error = 'To\'g\'ri narx kiriting';
        } else if (name.length > 50) {
            error = "Mahsulot nomi uzunligi eng ko'pi 50ta bo'lishi kerak";
        }
        return error;
    };

    const handleNameChange = (e) => {
        setProductName(e.target.value);
        setError(validateFields(e.target.value, productPrice));
    };

    const handlePriceChange = (e) => {
        setProductPrice(e.target.value);
        setError(validateFields(productName, e.target.value));
    };

    const handleDiscountChange = (e) => {
        setProductDiscount(e.target.value);
    };

    if (loading) {
        return (
            <Stack spacing={4} direction='column' align='center' marginTop='100px'>
                <Spinner size="xl" color={themeParams.link_color}/>
                <Text color={themeParams.text_color}>Yuklanmoqda...</Text>
            </Stack>
        );
    }

    return (
        <>
            <Stack spacing={4} direction='column' align='center' marginTop='10px'>
                <Button backgroundColor={themeParams.link_color} size='sm' width='370px'>
                    <Text fontSize='xl' color={themeParams.button_text_color}>Mahsulotlar</Text>
                </Button>
                {products.map((product) => (
                    <Stack key={product.id} direction='row' spacing={2} alignItems='center'>
                        <ChakraLink as={ReactRouterLink} to={`/product/${product.id}`}
                                    onClick={(e) => e.preventDefault()}>
                            <Button
                                backgroundColor={themeParams.link_color}
                                size='lg'
                                width='250px'
                                onClick={() => handleEdit(product.id, product.name, product.price, product.discount)}
                            >
                                <Text color={themeParams.button_text_color}>{product.name}</Text>
                            </Button>
                        </ChakraLink>
                        <IconButton
                            icon={<FaPen/>}
                            backgroundColor='blue'
                            color='white'
                            size='lg'
                            aria-label={`Edit ${product.name}`}
                            width='50px'
                            onClick={() => handleEdit(product.id, product.name, product.price, product.discount)}
                        />
                        <IconButton
                            icon={<FaTrash/>}
                            backgroundColor='red'
                            color='white'
                            size='lg'
                            aria-label={`Delete ${product.name}`}
                            width='50px'
                            onClick={() => handleDelete(product.id)}
                        />
                    </Stack>
                ))}
            </Stack>

            <Modal isOpen={isModalOpen} onClose={handleCancel}>
                <ModalOverlay/>
                <ModalContent backgroundColor={themeParams.section_bg_color}>
                    <ModalHeader color={themeParams.text_color}>
                        {selectedProductId ? "Mahsulotni tahrirlash" : "Yangi mahsulot yaratish"}
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            placeholder='Mahsulot nomini kiriting'
                            value={productName}
                            onChange={handleNameChange}
                            isInvalid={!!error}
                            errorBorderColor='red.300'
                            width='100%'
                            color={themeParams.text_color}
                        />
                        <Input
                            placeholder='Mahsulot narxini kiriting'
                            type='number'
                            value={productPrice}
                            onChange={handlePriceChange}
                            isInvalid={!!error}
                            errorBorderColor='red.300'
                            width='100%'
                            color={themeParams.text_color}
                            mt={4}
                        />
                        <Input
                            placeholder='Chegirma (ixtiyoriy)'
                            type='number'
                            value={productDiscount}
                            onChange={handleDiscountChange}
                            width='100%'
                            color={themeParams.text_color}
                            mt={4}
                        />
                        {error && <Text color='red.500'>{error}</Text>}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={handleCancel}>
                            <Text variant='ghost' color={themeParams.text_color}>Bekor qilish</Text>
                        </Button>
                        <Button backgroundColor={themeParams.link_color} onClick={handleSubmit}>
                            <Text
                                color={themeParams.button_text_color}>{selectedProductId ? "Saqlash" : "Yaratish"}</Text>
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
