import React, {useEffect, useState} from "react";
import {
    Button,
    IconButton,
    Input,
    Link as ChakraLink,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Stack,
    Text
} from '@chakra-ui/react';
import {Link as ReactRouterLink} from 'react-router-dom';
import {Cafe, themeParams} from "../api";
import {FaPen, FaTrash} from 'react-icons/fa';
import {changeBackgroundColor} from '../theme';

export default function CafeControl(props) {
    const [cafes, setCafes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCafeId, setSelectedCafeId] = useState(null);
    const [cafeName, setCafeName] = useState('');
    const [error, setError] = useState('');
    changeBackgroundColor(themeParams.bg_color)
    Telegram.WebApp.enableClosingConfirmation()
    useEffect(() => {
        async function fetchCafes() {
            try {
                const response = await Cafe.get();
                setCafes(response.data);
            } catch (error) {
                console.error('Error fetching cafes:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchCafes();
    }, []);

    const deleteCafe = async (id) => {
        try {
            await Cafe.delete(id);
            setCafes((prevCafes) => prevCafes.filter(cafe => cafe.id !== id));
        } catch (error) {
            if (error.detail && error.detail === 'CafeNotFound') {
                Telegram.WebApp.showAlert('Bu kafe mavjud emas');
            } else {
                Telegram.WebApp.showAlert('Kutilmagan xatolik');
            }
        }
    }

    const handleDelete = (id) => {
        Telegram.WebApp.showConfirm('Ushbu kafeni o\'chirilishini tasdqilang?', function (isConfirmed) {
            if (isConfirmed) {
                deleteCafe(id);
            }
        });
    };

    const handleCreateNewCafe = () => {
        setSelectedCafeId(null);
        setCafeName('');
        setError('');
        setIsModalOpen(true);
    };

    const handleEdit = (id, name) => {
        setSelectedCafeId(id);
        setCafeName(name);
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setCafeName('');
        setSelectedCafeId(null);
        setError('');
    };
    Telegram.WebApp.BackButton.hide()
    Telegram.WebApp.MainButton.onClick(handleCreateNewCafe);
    Telegram.WebApp.MainButton.text = 'Yangi kafe yaratish'
    Telegram.WebApp.MainButton.show()

    const handleSubmit = async () => {
        const validationError = validateName(cafeName);
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            if (selectedCafeId) {
                await Cafe.update(selectedCafeId, cafeName);
                setCafes(cafes.map(cafe => cafe.id === selectedCafeId ? {...cafe, name: cafeName} : cafe));
            } else {
                const createdCafe = await Cafe.create(cafeName);
                setCafes((prevCafes) => [...prevCafes, createdCafe.data]);
            }
            setIsModalOpen(false);
            setCafeName('');
            setSelectedCafeId(null); // Clear selectedCafeId after submit
        } catch (error) {
            if (error.detail && error.detail === 'UserLimitExceeded') {
                setError('Sizning kafe yaratish limitingiz tugadi');
            } else if (error.detail && error.detail === 'CafeNotFound') {
                setError('Bu kafe mavjud emas');
            } else {
                setError('Kutilmagan xatolik');
            }
        }
    };

    const validateName = (value) => {
        let error = '';
        if (!value) {
            error = 'Kafe nomini kiriting';
        } else if (value.length > 20) {
            error = "Kafe nomi uzunligi eng ko'pi 20ta bo'lishi kerak";
        }
        return error;
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setCafeName(value);
        setError(validateName(value));
    };

    if (loading) {
        return (
            <Stack spacing={4} direction='column' align='center' marginTop='100px'>
                <Spinner size="xl" color={themeParams.link_color}/>
                <Text color={themeParams.text_color}>Yuklanmoqda...</Text>
            </Stack>
        );
    }

    return (
        <>
            <Stack spacing={4} direction='column' align='center' marginTop='10px'>
                <Button backgroundColor={themeParams.link_color} size='sm' width='370px'>
                    <Text fontSize='xl' color={themeParams.button_text_color}>Kafe va restoranlar</Text>
                </Button>
                {cafes.map((cafe) => (
                    <Stack key={cafe.id} direction='row' spacing={2} alignItems='center'>
                        <ChakraLink as={ReactRouterLink} to={`/category/${cafe.id}`}>
                            <Button
                                backgroundColor={themeParams.link_color}
                                size='lg'
                                width='250px'
                            >
                                <Text color={themeParams.button_text_color}>{cafe.name}</Text>
                            </Button>
                        </ChakraLink>
                        <IconButton
                            icon={<FaPen/>}
                            backgroundColor='blue'
                            color='white'
                            size='lg'
                            aria-label={`Edit ${cafe.name}`}
                            width='50px'
                            onClick={() => handleEdit(cafe.id, cafe.name)}
                        />
                        <IconButton
                            icon={<FaTrash/>}
                            backgroundColor='red'
                            color='white'
                            size='lg'
                            aria-label={`Delete ${cafe.name}`}
                            width='50px'
                            onClick={() => handleDelete(cafe.id)}
                        />
                    </Stack>
                ))}
            </Stack>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay/>
                <ModalContent backgroundColor={themeParams.section_bg_color}>
                    <ModalHeader color={themeParams.text_color}>
                        {selectedCafeId ? "Kafe nomini o'zgartirish" : "Yangi kafe yaratish"}
                    </ModalHeader>
                    <ModalBody>
                        <Input
                            placeholder='Kafe nomini kiriting'
                            value={cafeName}
                            onChange={handleNameChange}
                            isInvalid={!!error}
                            errorBorderColor='red.300'
                            width='100%'
                            color={themeParams.text_color}
                        />
                        {error && <Text color='red.500'>{error}</Text>}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={handleCancel}>
                            <Text variant='ghost' color={themeParams.text_color}>Bekor qilish</Text>
                        </Button>
                        <Button backgroundColor={themeParams.link_color} onClick={handleSubmit}>
                            <Text color={themeParams.button_text_color}>{selectedCafeId ? "Saqlash" : "Yaratish"}</Text>
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
